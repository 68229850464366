import TYPES from '@/types';
import Vue from 'vue';

import { currencyFormat } from '@/vue-app/utils/currency';

// Application
import GetFinalInvestmentDateCalculatorQuery
  from '@/modules/flagship/investor-goal/final-investment-date-calculator/application/queries/get-final-investment-date-calculator-query';
import {
  SearchAllianzAccountReportQuery,
} from '@/modules/my-investment/allianz/allianz-account-report/application/queries';
import GetCustomerInvestorGoalBalanceQuery
  from '@/modules/flagship/customer-investor-goal-balance/application/query/get-customer-investor-goal-balance';
import RetirementInvestorGoalCommand
  from '@/modules/flagship/retirement-investor-goal/application/commands/retirement-investor-goal-command';

// Domain
import {
  FinalInvestmentDateCalculatorDto,
} from '@/modules/flagship/investor-goal/final-investment-date-calculator/domain/dtos/final-investment-date-calculator-dto';
import {
  RetirementInvestorGoalEntity,
} from '@/modules/flagship/retirement-investor-goal/domain/entities/retirement-investor-goal-entity';
import {
  CustomerInvestorGoalBalanceQueryEntity,
} from '@/modules/flagship/customer-investor-goal-balance/domain/entities/customer-investor-goal-balance-query-entity';
import {
  CreateInvestorGoalStateManager,
} from '@/modules/flagship/investor-goal/investor_goal/domain/state/create-investor-goal-state-manager';
import {
  GoalsDashboardProgressStateManager,
} from '@/modules/goals-dashboard/progress/domain/state/goals-dashboard-progress-state-manager';
import {
  StateManager,
} from '@/modules/my-investment/allianz/recurring-contributions/domain/state/state-manager';
import { DatetimeValue } from '@/modules/shared/domain/value-objects/datetime-value';
import Inject from '@/modules/shared/domain/di/inject';
import { Values } from '@/modules/shared/domain/i18n/types';
import Translator from '@/modules/shared/domain/i18n/translator';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import { DateFormatter } from '@/modules/shared/domain/date-formatters';

export default class RetirementFundSummaryViewModel {
  @Inject(TYPES.GET_FINAL_INVESTMENT_DATE_CALCULATOR_QUERY)
  private readonly get_final_investment_date_query!: GetFinalInvestmentDateCalculatorQuery;

  @Inject(TYPES.SEARCH_ALLIANZ_ACCOUNT_REPORT_QUERY)
  private readonly search_allianz_account_report_query!: SearchAllianzAccountReportQuery;

  @Inject(TYPES.GET_CUSTOMER_INVESTOR_GOAL_BALANCE_QUERY)
  private readonly get_customer_investor_goal_balance_query!: GetCustomerInvestorGoalBalanceQuery;

  @Inject(TYPES.RETIREMENT_INVESTOR_GOAL_COMMAND)
  private readonly create_retirement_invest_command!: RetirementInvestorGoalCommand;

  @Inject(TYPES.CREATE_INVESTOR_GOAL_STATE_MANAGER)
  readonly create_investor_goal_state_manager!: CreateInvestorGoalStateManager;

  @Inject(TYPES.GOALS_DASHBOARD_PROGRESS_STATE_MANAGER)
  private readonly progress_state_manager!: GoalsDashboardProgressStateManager;

  @Inject(TYPES.ALLIANZ_RECURRING_CONTRIBUTIONS_STATE_MANAGER)
  readonly recurring_contributions_state_manager!: StateManager;

  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.DATETIME_VALUE)
  readonly datetime_value!: DatetimeValue;

  @Inject(TYPES.DATE_FORMATTER)
  readonly date_formatter!: DateFormatter;

  readonly i18n_namespace = 'components.flagship.flagship-goals.create-goal.retirement-fund.summary';

  readonly view: Vue;

  investor_goal_state = this.create_investor_goal_state_manager.state;

  progress_state = this.progress_state_manager.state;

  recurring_contributions_state = this.recurring_contributions_state_manager.state;

  initial_investment_date = '';

  final_investment_date = '';

  recommend_amount = 0;

  has_active_goals = false;

  balance = 0;

  constructor(view: any) {
    this.view = view;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  items = [
    {
      id: 'linked_with',
      label: this.translate('linked_with'),
      value: 'SOWOS Wealth',
      tooltip: this.translate('pocket_tooltip'),
    },
    {
      id: 'desired_amount',
      label: this.translate('desired_amount'),
      value: '',
    },
    {
      id: 'initial_amount',
      label: this.translate('initial_amount'),
      value: '',
    },
    {
      id: 'monthly_contribution',
      label: this.translate('monthly_contribution'),
      value: '',
    },
    {
      id: 'monthly_pension_desired',
      label: this.translate('monthly_pension_desired'),
      value: '',
    },
    {
      id: 'target_date',
      label: this.translate('target_date'),
      value: '',
    },
  ]

  loadRetirementInvestorGoal = async () => {
    this.recommend_amount = this.investor_goal_state.investor_goal.monthly_required_amount || 0;
    this.items[1].value = `${currencyFormat(
      this.investor_goal_state.investor_goal.accumulated_amount || 0,
    )} MXN`;
    this.items[2].value = `${currencyFormat(
      this.investor_goal_state.investor_goal.initial_amount_adjusted || 0,
    )} MXN`;
    this.items[3].value = `${currencyFormat(
      this.investor_goal_state.investor_goal.monthly_required_amount || 0,
    )} MXN`;
    this.items[4].value = `${currencyFormat(
      this.investor_goal_state.retirement_fund.calculated_pension,
    )} MXN`;

    await this.loadFinalInvestmentDate(
      this.investor_goal_state.retirement_fund.retirement_range_adjusted * 12,
    );
  }

  loadFinalInvestmentDate = async (months_to_add: number) => {
    try {
      const initial_investment_date = this.datetime_value.create().toString();
      this.initial_investment_date = initial_investment_date;
      const payload: FinalInvestmentDateCalculatorDto = {
        initial_investment_date,
        period_in_months: months_to_add,
      };
      const { final_investment_date } = await this.get_final_investment_date_query.execute(payload);
      this.final_investment_date = final_investment_date;
      this.items[5].value = this.date_formatter.formatDate(
        final_investment_date, 'DD/MM/YYYY',
      );
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_final_investment_date_query'));
    }
  }

  setState = () => {
    this.investor_goal_state.investor_goal.initial_investment_date = this.initial_investment_date;
    this.investor_goal_state.investor_goal.final_investment_date = this.final_investment_date;
    this.investor_goal_state.available_balance = this.balance;
  }

  createRetirementInvestorGoal = async () => {
    const retirement_investor_goal_entity: RetirementInvestorGoalEntity = {
      id: this.investor_goal_state.investor_goal.id,
      retirement_age_adjusted: this.investor_goal_state.retirement_fund
        .retirement_age_adjusted,
      issued_age: this.investor_goal_state.retirement_fund.issued_age,
      retirement_range_adjusted: (this.investor_goal_state.retirement_fund
        .retirement_range_adjusted * 12)
        .toString(),
      pension_range_adjusted: (this.investor_goal_state
        .retirement_fund.pension_range_adjusted * 12),
      monthly_pension_adjusted: this.investor_goal_state.retirement_fund.calculated_pension
        .toString(),
      investor_goal: {
        investor_profile_id: this.investor_goal_state.investor_goal.investor_profile_id,
        initial_amount: this.investor_goal_state.investor_goal.initial_amount,
        initial_amount_adjusted: this.investor_goal_state.investor_goal.initial_amount_adjusted,
        accumulated_amount: this.investor_goal_state.investor_goal.accumulated_amount,
        monthly_required_amount: this.investor_goal_state.investor_goal.monthly_required_amount
          .toString(),
        initial_investment_date: this.investor_goal_state.investor_goal.initial_investment_date,
        final_investment_date: this.investor_goal_state.investor_goal.final_investment_date,
      },
    };

    await this.create_retirement_invest_command.execute(retirement_investor_goal_entity);
    this.investor_goal_state.goal_created = true;
  }

  createCustomInvestorGoalWithContributions = () => {
    this.view.$emit('createAndLinkGoal');
  }

  validateLimitReached = () => (
    ((this.recurring_contributions_state.item.contributions.length > 0
    && (this.recurring_contributions_state.item.contributions[0].amount + 500) > 50000)
    && (this.recurring_contributions_state.item.contributions.length > 1
    && (this.recurring_contributions_state.item.contributions[1].amount + 500) > 50000))
  )

  prevStep = () => {
    this.view.$emit('prevStep');
  }

  nextStep = async () => {
    try {
      this.setState();
      if (!this.investor_goal_state.associated_product_contracted) {
        await this.createRetirementInvestorGoal();
        this.investor_goal_state.goal_created = true;
        this.view.$emit('endProcess');
      } else if (this.validateLimitReached()) {
        this.investor_goal_state.contribution_limit_reached = true;
        this.createCustomInvestorGoalWithContributions();
      } else {
        this.view.$emit('nextStep');
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.create_goal'));
    }
  }

  getAllianzBalance = async () => {
    try {
      const allianz_account_report_list = await this.search_allianz_account_report_query.execute(
        this.investor_goal_state.investor_goal.customer_id,
      );
      if (allianz_account_report_list.length) {
        allianz_account_report_list.forEach((position) => {
          if (position.found_code === 'SWSRFP') {
            this.balance = position.final_balance_mxn || 0;
          }
        });
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_allianz_balance'));
    }
  }

  loadCustomerInvestorGoalBalance = async () => {
    try {
      if (this.progress_state.has_any_goal_linked_wealth) {
        const customer_investor_goal_balance: CustomerInvestorGoalBalanceQueryEntity = {
          investment_product_id: this.investor_goal_state.associated_product_id,
          reload: true,
        };
        const { unassigned_balance } = await this.get_customer_investor_goal_balance_query
          .execute(customer_investor_goal_balance);
        this.balance = unassigned_balance;
      } else {
        await this.getAllianzBalance();
      }
    } catch (error) {
      if (JSON.parse(error).status_code !== 404) {
        this.message_notifier.showErrorNotification(this.translate('errors.load_customer_investor_goal_balance'));
      } else {
        await this.getAllianzBalance();
      }
    }
  }

  initialize = async () => {
    if (this.investor_goal_state.associated_product_contracted) {
      await this.loadCustomerInvestorGoalBalance();
    }
    await this.loadRetirementInvestorGoal();
  }
}
